import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.scss';
import ProjectCarousel from "./components/basics/ProjectCarousel";
import Contact from "./pages/Contact";
import Dashboard from "./pages/Dashboard";
import Error404 from "./pages/Error404";
import Hobbies from "./pages/Hobbies";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import Politics from "./pages/Politics";
import WhoIAm from "./pages/WhoIAm";
import TechProjects from "./pages/TechProjects";
import CubaLibriPage from "./pages/subpage/CubaLibri";
import Devlog from "./pages/subpage/Devlog";
import DndPage from "./pages/subpage/DnD";
import Election22 from "./pages/subpage/Election2022";
import Resume from "./pages/subpage/Resume";


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCZT9uCFszUVOCLM0-Z_4qOHa88vJueESI",
  authDomain: "edorta-8b60d.firebaseapp.com",
  projectId: "edorta-8b60d",
  storageBucket: "edorta-8b60d.firebasestorage.app",
  messagingSenderId: "514343394965",
  appId: "1:514343394965:web:c92eccb97edc1327c7859c",
  measurementId: "G-MYKMPDE4LS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="contacts" element={<Contact />} />
          <Route path="tech-projects" element={<TechProjects />} />
          <Route path="tech-projects/projects" element={<ProjectCarousel />} />
          <Route path="tech-projects/developer-journal" element={<Devlog />} />
          <Route path="who-i-am/cv" element={<Resume />} />
          <Route path="who-i-am" element={<WhoIAm />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="politics" element={<Politics />} />
          <Route path="politics/elections22" element={<Election22 />} />
          <Route path="hobbies/cuba-libri" element={<CubaLibriPage />} />
          <Route path="hobbies/dnd" element={<DndPage />} />
          <Route path="hobbies" element={<Hobbies />} />
          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);