import { Helmet } from 'react-helmet';
import BiColumnImageText from '../components/BiColumnImageText';
import PagesEnum from '../model/PagesEnum';
import society from "./../assets/images/dnd/gypsiadina.webp";
import logocuba from './../assets/images/pages/hobby/cubalibri.png';
import './../css/home.scss';
// import HeroicText from '../components/basics/HeroicText';

const Hobbies = () => {
  return (
    <main>
      <Helmet>
        <title>Hobbies | edorta.it</title>
        <meta name="description" content="Scopri le mie passioni e hobby, tra cui la programmazione, la lettura e il gioco di ruolo Dungeons & Dragons." />
      </Helmet>

      <BiColumnImageText text={PagesEnum.CUBALIBRI.page} link={PagesEnum.CUBALIBRI.link} img={logocuba}></BiColumnImageText>
      <BiColumnImageText text={PagesEnum.DND.page} link={PagesEnum.DND.link} img={society}></BiColumnImageText>


    </main>

  );
};

export default Hobbies;