import { Helmet } from "react-helmet";
import Login from "../components/Login";

const Dashboard = () => {
    return (
        <main className="dashboard">
            <Helmet>
                <title>Dashboard | edorta.it</title>
                <meta name="description" content="Accedi alla dashboard personale di Edoardo Orta per monitorare il tuo progresso, progetti e altro." />
            </Helmet>

            <Login></Login>
        </main>
    );
};

export default Dashboard;