import React from 'react';
import { Helmet } from 'react-helmet';
import ButtonLink from '../../components/basics/ButtonLink';
import BiColumnText from '../../components/BiColumnText';
import BiColumnComponents from '../../components/BiColumnComponents';
import SectionTitle from '../../components/basics/SectionTitle';

const cubaLibri = (<p>
    Cuba Libri è un'associazione culturale dinamica con sede ad Asti, nata con l'obiettivo di unire la passione per i libri alla socialità in un ambiente informale e accogliente. Il nome dell'associazione è ispirato al famoso cocktail cubano, simbolo di una fusione tra letteratura e discussione rilassata. Dalla sua fondazione, Cuba Libri è rapidamente diventata un punto di riferimento per gli amanti della lettura, organizzando incontri settimanali in cui i partecipanti si ritrovano per discutere di un libro scelto, seguiti da un aperitivo. I generi trattati spaziano dalla letteratura classica a quella contemporanea, offrendo una piattaforma per autori conosciuti e emergenti.
</p>);
const meInCuba = (<p>
    Dal 2023 faccio parte del Consiglio Direttivo di Cuba Libri e sono un accanito fan di Murakami. Nel 2024 mi sono candidato al ruolo di segretario, sebbene abbia ottenuto il 36,7% delle preferenze, non sono stato eletto. Durante la mia partecipazione, ho avuto il privilegio di presentare il libro Il Custode di Francesca Tamburini. Una delle mie principali contribuzioni è stata quella di guidare lo sviluppo del sito web dell'associazione, uno strumento fondamentale per migliorare la comunicazione e l'interazione con la comunità.
</p>);

const CubaLibriPage = () => {
    return (
        <main>
            <Helmet>
                <title>Cuba Libri | edorta.it </title>
                <meta name="description" content="Cuba Libri è un'associazione culturale ad Asti che unisce la passione per la lettura e la socialità. Edoardo Orta è membro del Consiglio Direttivo." />
                <meta name="keywords" content="Cuba Libri, associazione culturale Asti, eventi libro Asti, lettura e socialità, club del libro, libri e aperitivo, cultura, incontri culturali Asti, libri, community di lettura" />
            </Helmet>

            <BiColumnComponents
                main={(<SectionTitle title="Cuba Libri" />)} classes={"padded"} side={(<ButtonLink link={{
                    shownText: "Segui Cuba Libri su Instagram",
                    path: "https://www.instagram.com/Cubalibri_"
                }}
                />)}></BiColumnComponents>

            <BiColumnText text={{ title: "Cos'è Cuba Libri?", paragraph: "Un bicchiere di vino, un libro e qualche amico", otherText: cubaLibri }} ></BiColumnText>
            <BiColumnText text={{ title: "Chi sono io per Cuba Libri?", paragraph: "Board member dal 2023", otherText: meInCuba }} ></BiColumnText>

        </main>
    );
}

export default CubaLibriPage;
