
function YoutubePlayer(props) {

    return (<>
        {props.url ? (
            <iframe
                className="player youtube-player"
                src={props.url}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen></iframe>


        ) : (<></>)
        }
    </>

    );
}
export default YoutubePlayer;