const PagesEnum = {
  CUBALIBRI: {
    link: {
      path: "/hobbies/cuba-libri",
      shownText: "Scopri di più su Cuba Libri!"
    },
    page: {
      title: "Cuba Libri - Un progetto culturale ad Asti",
      paragraph: "Cuba Libri è un'associazione culturale nata per unire la passione per i libri con l'incontro e il confronto. Come membro del Consiglio Direttivo e partecipante attivo, contribuisco a diversi progetti, tra cui l'organizzazione di eventi, lo sviluppo del sito web e molto altro. Scopri Cuba Libri e come puoi partecipare alle nostre iniziative culturali!"
    }
  },

  DND: {
    link: {
      path: "/hobbies/dnd",
      shownText: "Esplora il mondo di Dungeons & Dragons!"
    },
    page: {
      title: "Dungeons & Dragons - La mia passione",
      paragraph: "Da Dungeon Master appassionato, creo avventure uniche e personalizzate che portano i miei giocatori in mondi GrecoRomani e Steampunk. Il gioco per me è un'arte, un'opportunità di raccontare storie e vivere epiche avventure. Vieni a scoprire le mie creazioni, i personaggi e i mostri che ho realizzato, e lasciati ispirare dal mondo di D&D!"
    }
  },

  PROJECTS: {
    link: {
      path: "/tech-projects/projects",
      shownText: "Scopri i miei progetti!"
    },
    page: {
      title: "Progetti di Edoardo Orta",
      paragraph: "Dal design di siti web personali alla creazione di webapp per aziende agricole e associazioni culturali, mi occupo di sviluppo full-stack in diverse tecnologie e linguaggi, come React, Java, MongoDB e molto altro. Dai un'occhiata ai miei progetti e scopri come posso aiutarti a realizzare la tua idea!"
    }
  },
  WHO_I_AM: {
    link: {
      path: "/who-i-am",
      shownText: "Chi sono"
    },
    page: {
      title: "Chi sono",
      paragraph: "Scopri di più su di me, la mia carriera, la mia esperienza e le mie passioni."
    }
  },
  
  POLITICS: {
    link: {
      path: "/politics",
      shownText: "Attività Politiche"
    },
    page: {
      title: "Attività Politiche",
      paragraph: "Una panoramica delle mie iniziative e del mio coinvolgimento politico."
    }
  },
  HOBBIES: {
    link: {
      path: "/hobbies",
      shownText: "Passioni e Hobby"
    },
    page: {
      title: "Passioni e Hobby",
      paragraph: "Dai un'occhiata ai miei interessi personali e progetti creativi."
    }
  },
  CONTACTS: {
    link: {
      path: "/contacts",
      shownText: "Contatti"
    },
    page: {
      title: "Contatti",
      paragraph: "Rimani in contatto e scopri come contattarmi per ulteriori informazioni."
    }
  }

};

export default PagesEnum;