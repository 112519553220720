import React from 'react';

// Funzione helper per caricare immagini da una directory statica
const loadImages = (context) => context.keys().map(context);

// Carica tutte le immagini dalla directory specifica
const images = loadImages(require.context('../../assets/images/dnd', false, /\.(jpg|jpeg|png|gif)$/));

const DynamicCarousel = () => {
    if (images.length === 0) return <div>Nessuna immagine disponibile</div>;

    return (
        <div className='dynamic-carousel'>
            {images.map((img, index) => (
                <div key={index}>
                    <img src={img} alt={`Carousel ${index + 1}`} loading="lazy" style={{ width: "100%" }} />
                </div>
            ))}
        </div>
    );
};

export default DynamicCarousel;
