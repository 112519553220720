import { Helmet } from "react-helmet";
import Hr from "../../components/basics/Hr";

const Election22 = () => {
    return (
        <main class="mainBody container">
            <Helmet>
                <title>Elezioni 2022 | edorta.it</title>
                <meta name="description"
                    content="Scopri le proposte e la visione di Edoardo Orta per le Elezioni 2022: progresso ecosostenibile, sviluppo infrastrutturale, cultura e partecipazione per il futuro di Asti."
                />
            </Helmet>
            <Hr></Hr>
            <div class="welcome row">
                <h2>Vota Orta Edoardo!</h2>
            </div>
            <Hr></Hr>
            <div class="transparent"></div>
            <div class="hero">
                <div class="textBox">
                    <h2>Asti torni a essere un sogno</h2>
                    <p>
                        Mio nonno materno partì dalla Calabria con un sogno, quello di costruire
                        qui al Nord un futuro migliore per sé e la sua famiglia. Questo è quello
                        che voglio, che Asti torni a essere un sogno, non soltanto per gli
                        Astigiani, ma anche per quelle comunità minoritarie che hanno scelto di
                        stanziarsi cercando di costruire un futuro qui.
                    </p>
                </div>
            </div>
            <Hr></Hr>
            <div class="section row">
                <div class="textSection">
                    <h2>la formazione</h2>
                    <p>
                        Sono una persona dai mille interessi ma se vogliamo cercare un valore
                        comune a tutti, questa è la fiducia nella capacità della cittadinanza di
                        costruire una società complessa che si concretizzi nel progresso
                        collettivo. Questa passione mi ha portato prima a frequentare il Liceo
                        Classico V. Alfieri di Asti e il mio rione, Cattedrale, dove sono
                        cresciuto e dove tuttora ho scelto di vivere. Dopo la maturità ho
                        studiato antropologia culturale a Vienna, in Austria, lì ho conosciuto
                        una cultura simile ma non uguale alla nostra, aprendomi ad un’ottica più
                        europeista. Ho scelto di tornare perché ho un profondo legame con le mie
                        radici:
                    </p>

                    <p>Asti e la famiglia</p>
                    <p>
                        Sin da piccolo la politica mi ha appassionato, ad avvicinarmi a questo
                        mondo è stata mia madre, Graziella Mirigliani, che per il suo lavoro al
                        Centro Per l’Impiego, ha sempre avuto un ruolo attivo nella città
                        aiutando chi era in difficoltà a trovare lavoro: scegliendo di
                        candidarmi io oggi ho deciso di dare continuità a questa visione.
                    </p>
                </div>
                <div class="side" id="lavoro"></div>
            </div>
            <Hr></Hr>
            <div class="section row">
                <div class="textSection">
                    <h2>il lavoro</h2>
                    <p>
                        Ho iniziato a lavorare al Castello di Isola d’Asti come receptionist e
                        poi come segretario nello studio oculistico Pissarello - ora COOA- in
                        corso alla Vittoria. Nella prima esperienza ho imparato a conoscere i
                        nostri ospiti, i turisti, i quali ogni giorno scelgono attivamente di
                        venire a trovarci portando ricchezza al territorio, monetaria e
                        culturale. Nello Studio medico ho imparato a dare valore alla salute
                        della persona, che è un requisito essenziale per definire una società
                        pienamente avanzata.
                    </p>

                    <p>
                        Dopo una breve esperienza a Milano, ho deciso di iniziare a lavorare da
                        Asti in smart working per Banca Sella come programmatore informatico,
                        potendo così vivere appieno la città che amo e sperando che questa possa
                        essere la scelta di altri giovani come me che sono legati alle loro
                        radici; al contempo, però, la città si deve saper adattare a questi
                        nuovi cambiamenti offrendo nuovi servizi al passo coi tempi.
                    </p>
                </div>
                <div class="side" id="formazione"></div>
            </div>
            <Hr></Hr>
            <div class="section row">
                <div class="textSection">
                    <h2>chi sono</h2>
                    <p>
                        La domenica potrete incrociarmi mentre passeggio nei parchi lungo i
                        fiumi o all’area cani sul Borbore con il mio Opla, la mia “armatura” in
                        greco antico, oppure probabilmente mi riconoscerete in centro città a
                        spasso con la mia bicicletta con su scritto “I &lt;3 Italy”, fra un
                        aperitivo letterario e un giro fra i mercatini del centro, i quali spero
                        possano aumentare in numero e qualità, magari portando vita nella nuova
                        ZTL.
                    </p>
                    <p>
                        A trasmettermi la passione per la bici è stato mio nonno Francesco,
                        ”Cichin”, classe 1923, che ha combattuto durante la seconda guerra
                        mondiale come bersagliere in bicicletta e da quel momento non ha mai
                        smesso di usarla. Dopo la guerra ha avviato a Valfenera l’azienda
                        agricola che poi passò a mio padre Luigi e ora è gestita da mia sorella
                        Elena, questa azienda mi ha insegnato i valori del sacrificio e
                        dell’impresa, legandomi alla tradizione contadina con lo sguardo, però,
                        rivolto al futuro.
                    </p>
                </div>
                <div class="side" id="educazione"></div>
            </div>
            <Hr></Hr>
            <div class="section row">
                <div class="textSection">
                    <h2>Proposte</h2>
                    <p>
                        Per questo un progresso ecosostenibile è e deve essere uno dei valori
                        fondanti della battaglia politica moderna, per me questo si traduce in
                        un miglior servizio pubblico cittadino anche da e per le frazioni, un
                        aumento della rete ciclabile, l’approvazione della tangenziale sudovest,
                        per alleggerire il traffico del centro e permettere un supporto
                        infrastrutturale per l’industria astigiana. Asti è Capoluogo di
                        Provincia e come tale deve rappresentare un polo sociale, culturale e
                        politico, ripartendo dopo due anni di Covid, anni impattanti su tutti i
                        luoghi di aggregazione.
                    </p>
                    <p>
                        Si può ricominciare dalle Sagre, esempio della tradizione contadina e di
                        una città che vive, dall’ampliamento dell’orario della biblioteca
                        cittadina, luogo di cultura e utile per gli studenti fuorisede, e dalla
                        riapertura di un centro giovanile, per garantire anche una voce a chi si
                        sente parte di una cittadinanza attiva ma moderata.
                    </p>
                    <p>
                        Quella che vorrei è una Asti ricca, piena di vita, sicura e che possa
                        garantire un futuro solido, non soltanto ai giovani, come me, ma anche
                        alle loro famiglie. Mettiamo Asti al Centro, mettiamoci al Centro di
                        Asti!
                    </p>
                </div>
                <div class="side" id="hobby"></div>
            </div>
            <Hr></Hr>
        </main>
    );
};

export default Election22;