import BiColumnImageText from '../components/BiColumnImageText';
import PagesEnum from '../model/PagesEnum';
import mockups from "./../assets/images/pages/tech/mockups.png";
import './../css/home.scss';
// import HeroicText from '../components/basics/HeroicText';
import { Helmet } from 'react-helmet';
import BiColumnComponents from '../components/BiColumnComponents';
import Earth3dModel from '../components/Earth3dModel';
import Accordion from '../components/basics/Accordion';
import { DatiFiscali } from '../utils/Constants';
import certificationJson from './../assets/files/jsons/certification.json';


const contenutoSideImage = (<>
  <div className='contatto'>
    <h2 className='nome-contatto'>email</h2>
    <a href={`mailto:${DatiFiscali.EMAIL}`}><p className='valore-contatto'>{DatiFiscali.EMAIL}</p></a>
  </div>
  <div className='contatto'>
    <h2 className='nome-contatto'>cellulare</h2>
    <a href={`callto:${DatiFiscali.CELLULARE}`}><p className='valore-contatto'>{DatiFiscali.CELLULARE}</p></a>
  </div>
</>
);


const TechProjects = () => {
  return (
    <main>
      <Helmet>
        <title>Progetti e Informatica | edorta.it</title>
        <meta name="description" content="Scopri la storia di Edoardo Orta, Software Developer con esperienza nello sviluppo backend e frontend, appassionato di tecnologia e innovazione." />
      </Helmet>
      <Earth3dModel />
      <BiColumnImageText text={PagesEnum.PROJECTS.page} link={PagesEnum.PROJECTS.link} img={mockups}></BiColumnImageText>
      <BiColumnComponents main={contenutoSideImage} classes={"padded"} side={(<><h1>Certificati</h1><Accordion data={certificationJson} /></>)}></BiColumnComponents>

    </main>

  );
};

export default TechProjects;