import React, { useRef, useState } from 'react';
import Experience from '../../components/Experiences';
import { DatiFiscali } from '../../utils/Constants';
import corsiJson from './../../assets/files/jsons/corsi.json';
import formazioneJson from './../../assets/files/jsons/education.json';
import experiencesJson from './../../assets/files/jsons/experiences.json';
import skillsJson from './../../assets/files/jsons/skills.json';
import signature from './../../assets/images/pages/tech/cv/signature.png';
import './../../css/resume.scss';

import { Helmet } from 'react-helmet';
import Skillset from '../../components/Skillset';
import GdprConsent from '../../components/basics/GDPRConsent';

function Resume() {
    const resumeRef = useRef();
    const [isPreviewMode, setIsPreviewMode] = useState(false);

    const [showGuide, setShowGuide] = useState(false);

    const togglePreviewMode = () => {
        setIsPreviewMode(!isPreviewMode);
    };

    const toggleShowGuide = () => {
        setShowGuide(!showGuide);
    }

    return (
        <main className={`resume-wrapper ${isPreviewMode ? 'fullscreen' : ''}`}>
            <Helmet>
                <title>CV - Curriculum Vitae | edorta.it</title>
                <meta name="description" content="Scopri il Curriculum Vitae di Edoardo Orta, Software Developer con esperienza in backend, frontend, modellazione 3D e sviluppo creativo."
                />
            </Helmet>
            <button onClick={toggleShowGuide} className="btn btn-primary button-link print-invisible">
                {showGuide ? "Chiudi Guida" : "Scarica il CV come PDF"}
            </button>
            <button onClick={togglePreviewMode} className="preview-button btn btn-secondary button-link print-invisible">
                {isPreviewMode ? "Esci dall'Anteprima web" : "Anteprima Web CV"}
            </button>

            {/* Messaggio Guida */}
            {showGuide && (
                <div className="download-guide print-invisible">
                    <h1>Per salvare il CV come PDF:</h1>
                    <ol>
                        <li><strong>1</strong> Clicca su "Anteprima Web CV"</li>
                        <li><strong>2</strong> Fai clic destro sulla pagina e seleziona "Stampa" dal menu contestuale.</li>
                        <li><strong>3</strong> Nella finestra di stampa, seleziona "Salva come PDF" come stampante.</li>
                        <li><strong>4</strong> Assicurati di disattivare intestazioni e piè di pagina del browser nelle impostazioni avanzate di stampa.</li>
                        <li><strong>5</strong> Infine, fai clic su "Salva" e scegli una destinazione per il file PDF.</li>
                    </ol>

                </div>
            )}


            <div className={`resume-overlay `}>
                <div className={`resume-content  ${isPreviewMode ? 'resume-wrapper-inner' : ''}`} ref={resumeRef}>
                    {/* Contenuto del CV */}
                    <header className="resume-header">
                        <div className="header-main">
                            <div className="primary-info">
                                <h1>{DatiFiscali.NOME_UFFICIALE}</h1>
                                <div className="title">Software Developer</div>
                                <div>
                                    <a href={`mailto:${DatiFiscali.EMAIL}`}>
                                        <i className="far fa-envelope"></i> {DatiFiscali.EMAIL}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="header-secondary-info">
                            <div className="resume-social">
                                <div>
                                    <a href={`callto:${DatiFiscali.CELLULARE}`}>
                                        <i className="fas fa-mobile-alt"></i> {DatiFiscali.CELLULARE}
                                    </a>
                                </div>
                                <div>
                                    <a href="https://www.linkedin.com/in/edoardo-orta-396097123/">
                                        <i className="fab fa-linkedin"></i> linkedin
                                    </a>
                                </div>
                                <div>
                                    <a href="https://edorta.it/">
                                        <i className="fa-solid fa-globe"></i> https://edorta.it
                                    </a>
                                </div>
                                <div>
                                    <i className="fa-solid fa-car"></i> Patente B
                                </div>
                            </div>
                        </div>
                    </header>

                    <div className="resume-body">
                        <section className="resume-section summary-section">
                            <h2 className="resume-section-title">Su di me</h2>
                            <div className="resume-section-content">
                                <p>Sono un Software Developer con una solida esperienza in backend e frontend, orientato alla modellazione 3D e a un percorso creativo poliedrico. Oltre alle competenze tecniche in Java, Spring Boot, React e containerizzazione (Docker, Kubernetes), considero l’aspetto umano essenziale nel mio lavoro. Credo che una figura creativa non sia solo tecnica, ma debba creare esperienze che connettano le persone.

                                    Sono intenzionato ad applicare mie abilità nella modellazione 3D per progetti audiovisivi e digitali, puntando a soluzioni che siano sia tecniche sia profondamente coinvolgenti.</p>
                            </div>
                        </section>
                        <div className="main-bicolumn">
                            <section className="resume-section experience-section">
                                <h2 className="resume-section-title">Esperienze</h2>
                                <Experience array={experiencesJson} />
                            </section>
                            <section className="resume-section skills-section">
                                <h2 className="resume-section-title">Competenze</h2>
                                <Skillset skills={skillsJson}></Skillset>
                                <h2 className="resume-section-title">Corsi e certificati</h2>
                                <Skillset skills={corsiJson}></Skillset>
                            </section>
                        </div>
                        <div className="main-formation">
                            <section className="resume-section formation-section">
                                <h2 className="resume-section-title">Formazione</h2>
                                <Experience array={formazioneJson} />
                            </section>
                        </div>
                    </div>
                    <footer className='resume-footer'>
                        <div className='main-bicolumn'>
                            <GdprConsent />
                            <div className='signature'>
                                <img src={signature} alt="Signature" />
                            </div>
                        </div>
                    </footer>
                </div>
            </div>

        </main>
    );
}

export default Resume;
