import { Helmet } from "react-helmet";
import noimoderati from "../assets/images/pages/politics/logo noi moderati.png";
import BiColumnImageText from '../components/BiColumnImageText';

import './../css/home.scss';

const Politics = () => {
  return (
    <main>
      <Helmet>
        <title>Politica | edorta.it</title>
        <meta
          name="description"
          content="Esplora le idee politiche di Edoardo Orta: proposte per un futuro sostenibile, una città più vivibile e opportunità per tutti. Scopri il programma elettorale 2022 per Asti."
        />
      </Helmet>

      <BiColumnImageText
        text={{
          title: "Elezioni Comunali 2022",
          paragraph: "Scopri le mie idee e proposte per il futuro di Asti nel mio volantino elettorale. Leggi di più su come insieme possiamo migliorare la nostra città!"
        }}
        link={{
          shownText: "Scopri il mio programma",
          path: "/politics/elections22"
        }}
        img={noimoderati}
      />
    </main>

  );
};

export default Politics;