import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import SectionTitle from '../../components/basics/SectionTitle';
import BiColumnComponents from '../../components/BiColumnComponents';
import BiColumnText from '../../components/BiColumnText';
import YoutubePlayer from '../../components/YoutubePlayer';
import DynamicCarousel from '../../components/basics/DynamicCarousel';

const dndDescription = (
    <p>
        Dungeons & Dragons è una delle mie più grandi passioni. Da anni gioco e faccio il Dungeon Master (DM), creando mondi fantastici, avventure epiche e storie coinvolgenti per i miei amici. Il gioco non è solo un passatempo, ma un'opportunità di esplorare la creatività e costruire legami tra le persone. Ho creato diverse avventure personalizzate, ognuna con temi unici e sfide intriganti che mettono alla prova l'ingegno dei miei giocatori. La possibilità di immergersi in mondi fantastici e vivere avventure fuori dal comune è ciò che rende D&D così speciale per me.
    </p>
);

const dndProjects = (
    <p>
        Come Dungeon Master, ho creato molteplici avventure originali. Ogni storia che concepisco è un mix di enigmi, battaglie strategiche e narrazione coinvolgente. Non solo mi dedico alla creazione di avventure, ma mi occupo anche della personalizzazione dei personaggi e dei mostri, molti dei quali sono stati realizzati con l'aiuto dell'intelligenza artificiale. Nel tempo libero, ho deciso di collezionare e condividere queste creazioni per ispirare altri appassionati e arricchire la comunità.
    </p>
);

const dndSettings = (
    <p>
        Le mie ambientazioni preferite per le avventure di D&D sono quelle grecoromane e grecoromane steampunk. Questi mondi uniscono la grandezza dell'antichità classica con un tocco di tecnologia futuristica, creando ambientazioni ricche di contrasto e mistero, ideali per le mie storie coinvolgenti.
    </p>
);

const DndPage = () => {
    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    return (
        <main>
            <Helmet>
                <title>Dungeons & Dragons | edorta.it</title>
                <meta
                    name="description"
                    content="Scopri la mia passione per Dungeons & Dragons: mondi fantastici, avventure epiche e creazioni uniche come Dungeon Master."
                />
            </Helmet>
            <BiColumnText text={{ title: "Cos'è Dungeons & Dragons?", paragraph: "Un gioco che unisce fantasia, strategia e amicizia.", otherText: dndDescription }} />
            <BiColumnText text={{ title: "I miei progetti", paragraph: "Dungeon Master dal 2020", otherText: dndProjects }} />
            <BiColumnText text={{ title: "Le mie ambientazioni preferite", paragraph: "I mondi GrecoRomani e Grecoromane Steampunk", otherText: dndSettings }} />
            <BiColumnComponents
                main={

                    <SectionTitle title="Pergamon Shadows" id={"PS"}></SectionTitle>

                }
                classes="padded"
                side={

                    (<YoutubePlayer shownText="Ascolta il Trailer!" url="https://www.youtube.com/embed/I1Cl3N-2KbY?si=zy3w7s9HQAby83DJ"></YoutubePlayer>)
                }></BiColumnComponents>
                
            <div className='container'>
                <div className='row'>
                    <div className="carousel-section">
                        <h2>Le mie creazioni</h2>
                        <p>Le immagini dei personaggi e mostri che ho creato per i miei giochi di D&D sono hostate qui per i miei giocatori, per ispirare nuove avventure.</p>
                        <Button className='button-link' onClick={toggleModal}>
                            Scorri il carosello di immagini
                        </Button>
                        <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
                            <ModalHeader toggle={toggleModal}>Le mie creazioni</ModalHeader>
                            <ModalBody>
                                <DynamicCarousel imagesPath="../../assets/images/dnd" />
                            </ModalBody>
                        </Modal>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default DndPage;
